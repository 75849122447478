import { Controller } from "@hotwired/stimulus";

export default class Vendors__OrderVendorShowController extends Controller {
  static values = {
    currency: String,
    locale: String
  };

  static targets = [
    "discount",
    "discountTitle",
    "extendedPrice",
    "extendedPriceInput",
    "extendedPriceTitle",
    "quantity",
    "quantityInput",
    "quantityTitle",
    "name",
    "nameTitle",
    "pricePerUnitTitle",
    "received",
    "receivedTitle",
    "shipping",
    "shippingTitle",
    "shipped",
    "shippedTitle",
    "sku",
    "skuTitle",
    "subtotal",
    "subtotalValue",
    "subtotalTitle",
    "tax",
    "taxTitle",
    "total",
    "totalTitle",
    "unitPrice",
    "unitPriceInput",
    "unitOfMeasure",
    "unitOfMeasureTitle"
  ];

  copyTable() {
    let textToCopy = "";
    textToCopy += this.fetchInnerText("skuTitleTarget");
    textToCopy += this.fetchInnerText("nameTitleTarget");
    textToCopy += this.fetchInnerText("shippedTitleTarget");
    textToCopy += this.fetchInnerText("receivedTitleTarget");
    textToCopy += this.fetchInnerText("unitOfMeasureTitleTarget");
    textToCopy += this.fetchInnerText("quantityTitleTarget");
    textToCopy += this.fetchInnerText("pricePerUnitTitleTarget");
    textToCopy += this.fetchInnerText("extendedPriceTitleTarget");

    this.nameTargets.forEach((_, index) => {
      textToCopy += "\n";
      textToCopy += this.fetchInnerText("skuTargets", index);
      textToCopy += this.fetchInnerText("nameTargets", index);
      textToCopy += this.fetchInnerText("shippedTargets", index);
      textToCopy += this.fetchInnerText("receivedTargets", index);
      textToCopy += this.fetchInnerText("unitOfMeasureTargets", index);
      textToCopy += this.fetchInputValue("quantityInputTargets", index);
      textToCopy += `${this.buildCurrencyString(
        this.fetchInputValue("unitPriceInputTargets", index)
      )}\t`;
      textToCopy += this.fetchInnerText("extendedPriceTargets", index);
    });

    textToCopy += `\n\n${this.fetchInnerText(
      "subtotalTitleTarget"
    )} ${this.buildCurrencyString(
      this.fetchInputValue("subtotalValueTarget")
    )}`;

    textToCopy += `\n${this.fetchInnerText(
      "discountTitleTarget"
    )} ${this.buildCurrencyString(this.fetchInputValue("discountTarget"))}`;

    textToCopy += `\n${this.fetchInnerText(
      "shippingTitleTarget"
    )} ${this.buildCurrencyString(this.fetchInputValue("shippingTarget"))}`;

    textToCopy += `\n${this.fetchInnerText(
      "taxTitleTarget"
    )} ${this.buildCurrencyString(this.fetchInputValue("taxTarget"))}`;

    textToCopy += `\n${this.fetchInnerText(
      "totalTitleTarget"
    )} ${this.fetchInnerText("totalTarget")}`;

    navigator.clipboard.writeText(textToCopy);
  }

  fetchInnerText(target, index) {
    return this.fetchProperty(target, "innerText", index);
  }

  fetchInputValue(target, index) {
    return this.fetchProperty(target, "value", index);
  }

  fetchProperty(target, property, index) {
    try {
      if (index !== undefined) {
        return `${this[target][index][property]}\t `.replace(/\n/g, " ");
      }
      return `${this[target][property]}\t `.replace(/\n/g, " ");
    } catch (error) {
      return "";
    }
  }

  totalTargetConnected() {
    this.calculatePrices();
  }

  calculatePrices() {
    this.updateExtendedPrices();
    this.updateSubtotal();
    this.updateTotal();
  }

  updateExtendedPrices() {
    this.extendedPriceInputTargets.forEach((extendedPriceInput, index) => {
      const extendedPrice = (
        Number(this.unitPriceInputTargets[index].value) *
        Number(this.quantityInputTargets[index].value)
      ).toString();

      extendedPriceInput.value = extendedPrice;
      this.extendedPriceTargets[index].innerText = this.buildCurrencyString(
        extendedPrice
      );
    });
  }

  updateSubtotal() {
    let subtotal = 0;
    this.extendedPriceInputTargets.forEach((field) => {
      subtotal += Number(field.value);
    });

    this.subtotalValueTarget.value = subtotal.toString();
    this.subtotalTarget.innerText = this.buildCurrencyString(
      subtotal.toString()
    );
  }

  updateTotal() {
    const total =
      Number(this.subtotalValueTarget.value) +
      Number(this.shippingTarget.value) +
      Number(this.taxTarget.value);

    this.totalTarget.innerText = this.buildCurrencyString(
      (total - Number(this.discountTarget.value)).toString()
    );
  }

  buildCurrencyString(amount) {
    return Intl.NumberFormat(this.localeValue, {
      style: "currency",
      currency: this.currencyValue
    }).format(amount);
  }
}
