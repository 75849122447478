import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin--toggle-substitution-recommendations"
export default class Admin__ToggleSubstitutionRecommendationsController extends Controller {
  static targets = ["toggleSubRecsButton", "subRecsResults"];

  toggleSubRecs() {
    if (this.subRecsResultsTarget.hidden) {
      this.subRecsResultsTarget.hidden = false;
      this.toggleSubRecsButtonTarget.innerText =
        "Hide substitution recommendations";
    } else {
      this.subRecsResultsTarget.hidden = true;
      this.toggleSubRecsButtonTarget.innerText =
        "Show substitution recommendations";
    }
  }
}
