import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Consider using discrete data attributes to scope into certain elements instead of a broad query
    const anchors = this.element.querySelectorAll("a");
    if (anchors) {
      anchors.forEach((control) => {
        control.addEventListener("click", (event) => {
          this.dispatch("navigate", {
            detail: { content: { target: event.target, event } }
          });
        });
      });
    }
  }
}
