import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["amountField", "flexibleDue"];

  static values = { currency: String };

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    let total = 0;

    this.amountFieldTargets.forEach((field) => {
      const amount = parseFloat(field.value) || 0;

      total += amount;
    });

    this.flexibleDueTarget.textContent = this.formatCurrency(total);
  }

  formatCurrency(amount) {
    const currency = this.currencyValue || "USD";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency
    }).format(amount);
  }

  submitForm(event) {
    const form = this.element.closest("form");
    const flexibleDueElement = this.flexibleDueTarget;
    const paymentAmountString = form.dataset.paymentamount;
    const paymentAmount = JSON.parse(paymentAmountString);
    const paymentAmountCents = paymentAmount.cents || 0;

    const flexibleDue =
      parseFloat(flexibleDueElement.textContent.replace(/[^\d.-]/g, "")) * 100;

    if (flexibleDue < paymentAmountCents) {
      const overAllocatedMessage =
        "Over-allocation detected! Are you sure you want to save and create a Credit Memo for the difference?";
      // eslint-disable-next-line no-alert
      if (!window.confirm(overAllocatedMessage)) {
        event.preventDefault();
      }
    } else {
      const normalMessage =
        "Are you sure you want to save and allocate this payment?";
      // eslint-disable-next-line no-alert
      if (!window.confirm(normalMessage)) {
        event.preventDefault();
      }
    }
  }
}
