import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="budgets--accounting-code-row"
export default class extends Controller {
  static targets = [
    "disableHiddenField",
    "disableTextField",
    "enableRowLink",
    "disableRowLink"
  ];

  disableAccountingCode(e) {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      this.disableHiddenFieldTarget.removeAttribute("disabled");
      this.disableTextFieldTarget.setAttribute("value", "");
      this.disableTextFieldTarget.setAttribute("disabled", "true");
      e.target.classList.add("hidden");
      this.enableRowLinkTarget.classList.remove("hidden");
    }
  }

  enableAccountingCode(e) {
    e.preventDefault();
    this.disableHiddenFieldTarget.setAttribute("disabled", "true");
    this.disableTextFieldTarget.removeAttribute("disabled");
    e.target.classList.add("hidden");
    this.disableRowLinkTarget.classList.remove("hidden");
  }
}
