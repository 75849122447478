import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkouts--toggle-rewards"
export default class Checkouts__ToggleRewardsController extends Controller {
  static targets = ["lineLevelReward", "aggregateRewards"];

  // aggregateRewards should connect when aggregate rewards are >= $1
  // See n/checkouts/_rewards_summary.html.erb
  aggregateRewardsTargetConnected() {
    this.lineLevelRewardTargets.forEach((el) => {
      el.hidden = false;
    });
  }

  // aggregateRewards should disconnect when aggregate rewards drop to less than $1
  // See n/checkouts/_rewards_summary.html.erb
  aggregateRewardsTargetDisconnected() {
    this.lineLevelRewardTargets.forEach((el) => {
      el.hidden = true;
    });
  }
}
