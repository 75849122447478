import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accounting-code-types"
export default class extends Controller {
  static targets = ["accountingCodesFrame"];

  loadAccountingCodeType(event) {
    if (!event.target.value) {
      return;
    }
    const url = `${event.target.dataset.path}?accounting_code_type_id=${event.target.value}`;
    this.accountingCodesFrameTarget.src = url;
  }

  toggleAccountingCodeTypeView(e) {
    e.currentTarget.classList.toggle("rotate-90-counter-clockwise");
    e.currentTarget.classList.toggle("rotate-90-clockwise");
    this.accountingCodesFrameTarget.classList.toggle("hidden");
  }
}
