import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="off-catalog-orders--close-card"
export default class extends Controller {
  static values = {
    modalId: { type: String, default: "close-card-modal" }
  };

  connect() {}

  openModal(event) {
    event.preventDefault();
    /* global zestModalToggleHandler */
    zestModalToggleHandler(this.modalIdValue);
  }
}
