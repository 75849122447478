import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "captureButton",
    "captureLink",
    "persistButton",
    "persistLink",
    "goBackLink",
    "form"
  ];

  captureButtonTargetConnected(el) {
    if (el.dataset.navigationLink) {
      const url = new URL(this.urlStringFrom(el.dataset.navigationLink));
      const urlWithBackTo = this.encodeBackParam(url);

      el.addEventListener("click", () => {
        window.location = urlWithBackTo.toString();
      });
    }
  }

  captureLinkTargetConnected(el) {
    const url = new URL(el.href);
    const urlWithBackTo = this.encodeBackParam(url);
    el.href = urlWithBackTo;
  }

  persistButtonTargetConnected(el) {
    if (el.dataset.navigationLink) {
      const url = new URL(this.urlStringFrom(el.dataset.navigationLink));
      const urlWithBackTo = this.appendBackParamTo(url);

      el.addEventListener("click", () => {
        window.location = urlWithBackTo.toString();
      });
    }
  }

  persistLinkTargetConnected(el) {
    const url = new URL(el.href);
    const urlWithBackTo = this.appendBackParamTo(url);
    el.href = urlWithBackTo;
  }

  formTargetConnected(el) {
    const url = new URL(this.urlStringFrom(el.action));
    const urlWithBackTo = this.appendBackParamTo(url);
    el.action = urlWithBackTo.toString();
  }

  goBackLinkTargetConnected(el) {
    const currentSearchParams = new URLSearchParams(
      window.location.search.toString()
    );
    const backToParams = currentSearchParams.get("back_to");

    if (backToParams) {
      const backToUrl = new URL(el.href);

      const paramPairs = atob(backToParams).split("&");
      paramPairs.forEach((str) => {
        const [name, value] = str.split("=");
        backToUrl.searchParams.append(name, value);
      });

      el.href = backToUrl.toString();
    }
  }

  encodeBackParam(url) {
    const currentSearchParams = new URLSearchParams(
      window.location.search.toString()
    );

    if (currentSearchParams.toString() !== "") {
      url.searchParams.append("back_to", btoa(currentSearchParams));
    }

    return url;
  }

  appendBackParamTo(url) {
    const currentSearchParams = new URLSearchParams(
      window.location.search.toString()
    );

    if (currentSearchParams.get("back_to")) {
      url.searchParams.append("back_to", currentSearchParams.get("back_to"));
    }

    return url;
  }

  // Returns a full URL if it's given a partial path or an already complete URL.
  urlStringFrom(urlOrPath) {
    if (urlOrPath.match(window.location.host)) {
      return urlOrPath;
    }
    const fullUrl = new URL(window.location.origin);
    fullUrl.pathname = urlOrPath;
    return fullUrl.toString();
  }
}
