import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="off-catalog-orders--card-status-filter-switch"
export default class extends Controller {
  static targets = ["toggleInput"];

  toggleInputTargetConnected() {
    this.toggleInputTarget.addEventListener("click", (e) => {
      this.toggle(e);
    });
  }

  toggle(event) {
    const url = new URL(window.location);
    url.searchParams.set("only_open_cards", event.target.checked);
    // timeout waits .2 seconds for the toggle animation to complete
    setTimeout(() => {
      window.location = url.href;
    }, 200);
  }
}
