import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["discard", "save"];

  submit = (event) =>
    this.dispatch("submit", {
      detail: { content: { target: event.target, event } }
    });

  discard = (event) =>
    this.dispatch("discard", {
      detail: { content: { target: event.target, event } }
    });
}
