import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  static targets = ["locationBudgetsFrame", "loading"];

  hideLoadingScreen() {
    this.loadingTarget.hidden = true;
  }

  refresh(e) {
    const queryData = {};

    if (e.target.dataset.id) {
      queryData.accounting_code_id = e.target.dataset.id;
    }

    const queryParams = new URLSearchParams(queryData);
    this.loadingTarget.hidden = false;
    this.locationBudgetsFrameTarget.src = `${this.urlValue}?${queryParams}`;
  }
}
