import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    modalId: { type: String, default: "close-form-modal" },
    codeModalId: { type: String, default: "close-code-form-modal" }
  };

  static targets = ["accountingCodesContainer"];

  initialize() {
    this.getAllFormControls().forEach((control) => {
      if (control) {
        control.addEventListener("change", () => {
          this.formDirty = true;
        });
      }
    });

    this.getAccountingCodesFormControls().forEach((control) => {
      if (control) {
        control.addEventListener("change", () => {
          this.codesFormDirty = true;
        });
      }
    });
  }

  triggerModal(e) {
    if (this.formDirty || this.codesFormDirty) {
      e.detail.content.event.preventDefault();
      this.redirect = e.detail.content.target.href;

      /* global zestModalToggleHandler */
      zestModalToggleHandler(this.getDirtyModalId());
    }
  }

  getDirtyModalId = () => {
    if (this.codesFormDirty) {
      return this.codeModalIdValue;
    }
    return this.modalIdValue;
  };

  submitForm = () => this.element.submit();

  discardForm() {
    window.location.href = this.redirect;
  }

  getAccountingCodesFormControls = () => [
    ...this.accountingCodesContainerTarget.querySelectorAll("select")
  ];

  getAllFormControls = () => [
    ...this.element.querySelectorAll("fieldset [name^=off_catalog_order]"),
    this.element.querySelector("input[name=new_vendor_name]")
  ];
}
