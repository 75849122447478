import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="invoice-checkbox"
export default class extends Controller {
  static targets = [
    "input",
    "idList",
    "bulkNotesModal",
    "bulkMarkUncollectableModal",
    "bulkEditLateFeeModal",
    "bulkRefreshModal",
    "hiddenField",
    "selectAll"
  ];

  connect() {
    this.selectAllTarget.addEventListener("change", this.selectAll.bind(this));
  }

  selectAll() {
    const isChecked = this.selectAllTarget.checked;
    this.inputTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
    this.setSelectedInvoices();
  }

  openBulkNotesModal() {
    this.openModal(this.bulkNotesModalTarget);
  }

  closeBulkNotesModal() {
    this.closeModal(this.bulkNotesModalTarget);
  }

  openBulkMarkUncollectableModal() {
    this.openModal(this.bulkMarkUncollectableModalTarget);
  }

  closeBulkMarkUncollectableModal() {
    this.closeModal(this.bulkMarkUncollectableModalTarget);
  }

  openBulkEditLateFeeModal() {
    this.openModal(this.bulkEditLateFeeModalTarget);
  }

  closeBulkEditLateFeeModal() {
    this.closeModal(this.bulkEditLateFeeModalTarget);
  }

  openBulkRefreshModal() {
    this.openModal(this.bulkRefreshModalTarget);
  }

  closeBulkRefreshModal() {
    this.closeModal(this.bulkRefreshModalTarget);
  }

  setIdListFields(idLists) {
    idLists.forEach((idList) => {
      idList.innerHTML = this.generateHiddenFields(this.getCheckedIds()).join(
        " "
      );
    });
  }

  // eslint-disable-next-line consistent-return
  openModal(modalTarget) {
    if (this.getCheckedIds().length < 1) {
      // eslint-disable-next-line no-alert
      return alert("You must select an invoice before applying a bulk action");
    }
    modalTarget.classList.toggle("fade");
    modalTarget.classList.toggle("in");
    modalTarget.style.display = "block";

    this.setIdListFields(this.idListTargets);
  }

  closeModal(modalTarget) {
    modalTarget.classList.toggle("fade");
    modalTarget.classList.toggle("in");
    modalTarget.style.display = "none";
  }

  generateHiddenFieldsForPayment(invoiceList) {
    const selectedInvoicesList = invoiceList.map((invoice) => invoice.id);
    const htmlValues = selectedInvoicesList.map((id) => {
      return `<input type="hidden" name="selected_invoice_ids[]" value="${id}" />`;
    });
    return htmlValues;
  }

  generateHiddenFields(invoiceList) {
    const selectedInvoicesList = [];

    invoiceList.forEach((invoice) => {
      selectedInvoicesList.push(invoice.id);
    });

    const htmlValues = invoiceList.map((invoice) => {
      return `<input type="hidden" name="invoices" value="${selectedInvoicesList}" /><li> <a target=”_blank” href="/n/invoices/${invoice.view_token}"> ${invoice.to_s} </li>`;
    });
    return htmlValues;
  }

  getCheckedIds() {
    const selectedInvoices = [];
    this.inputTargets.forEach((target) => {
      if (target.checked) {
        selectedInvoices.push({
          id: target.value,
          view_token: target.dataset.view_token,
          to_s: target.dataset.to_s
        });
      }
    });
    return selectedInvoices;
  }

  setSelectedInvoices() {
    const hiddenField = this.hiddenFieldTarget;
    hiddenField.innerHTML = this.generateHiddenFieldsForPayment(
      this.getCheckedIds()
    ).join(" ");
  }
}
