import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "locationDropdown",
    "costCenterDropdown",
    "accountingCodesFrame",
    "accountingCodeDropdown",
    "loading"
  ];

  hideLoadingScreen() {
    this.loadingTarget.hidden = true;
  }

  handleCodeRelatedDropdownChange(event) {
    // Not all organizations use codes
    if (!this.hasAccountingCodesFrameTarget) {
      return;
    }

    event.target.dataset.dirty = "true";

    const queryData = {
      location_id: this.locationDropdownTarget.value || "",
      order_id: this.accountingCodesFrameTarget.dataset.orderId,
      dirty_location: this.locationDropdownTarget.dataset.dirty === "true"
    };

    if (this.hasCostCenterDropdownTarget) {
      queryData.cost_center_id = this.costCenterDropdownTarget.value || "";
      queryData.dirty_cost_center =
        this.costCenterDropdownTarget.dataset.dirty === "true";
    }

    this.accountingCodeDropdownTargets.forEach((dropdown) => {
      queryData[`code_ids[${dropdown.dataset.codeTypeId}]`] = dropdown.value;
    });

    const queryParams = new URLSearchParams(queryData);
    this.loadingTarget.hidden = false;
    this.accountingCodesFrameTarget.src = `${this.accountingCodesFrameTarget.dataset.url}?${queryParams}`;
  }
}
